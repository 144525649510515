import React from "react";
import Webcam from "react-webcam";

class CameraComponent extends React.Component {
  webcamRef = React.createRef();

  toggleFlash = async (on) => {
    const stream = this.webcamRef.current.stream;
    const track = stream.getVideoTracks()[0];

    if (track.getCapabilities) {
      const capabilities = track.getCapabilities();
      if (capabilities.torch) {
        try {
          await track.applyConstraints({ advanced: [{ torch: on }] });
        } catch (err) {
          console.error("Error applying constraints: ", err);
        }
      }
    }
  };

  render() {
    return (
      <div>
        <Webcam
          audio={false}
          ref={this.webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{ facingMode: "environment" }}
        />
        <button onClick={() => this.toggleFlash(true)}>Turn Flash On</button>
        <button onClick={() => this.toggleFlash(false)}>Turn Flash Off</button>
      </div>
    );
  }
}

export default CameraComponent;
